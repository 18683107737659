import React, { useState } from 'react'

const ButtonLoading = ({isLoadings,name}) => {
    // const [isLoadings,setIsLoadings]=useState(false)
    // const handleLogin=()=>{
    //     setIsLoadings(true);
    //     setTimeout(() => {
    //       setIsLoadings(false);
    //     }, 1000); 
    //   }
  return (
   <>
    <button className="loginBtn" type="submit"   disabled={isLoadings}>
                  {isLoadings ?  <i class="fa fa-spinner fa-spin" ></i> : name}
              </button>
   </>
  )
}

export default ButtonLoading