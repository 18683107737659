import React, { useEffect, useState } from "react";
import edit from "../asset/edit.png";
import remove from "../asset/delete.svg";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
} from "@mui/material";
// import { Form } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SidebarPay from "../component/Sidebar";
import { useQuery } from "react-query";
import { getUserTable } from "../globalAPI/globalAPI";
import axios from "axios";
import Loading from "../component/Loading";

export default function ViewUserPage(props) {
  const navigate = useNavigate();

  const { data, isLoading, isError,isRefetching } =  useQuery('getUserTable', getUserTable);
console.log("data_LOGIN",data?.data);
let details=data&&data?.data
console.log("DETAILS : ",details)
  const [searchName,setSearchName]=useState("")
  const handleChange=(e)=>{
    setSearchName(e.target.value)
  }
  const searchResult = details?.filter((product)=>{
    return(
      product.name?.toLowerCase().startsWith(searchName?.toLowerCase())||
      product.employeeId?.toLowerCase().toString().startsWith(searchName.toLowerCase())
    )
  }
 );
// DELET Start API
const HandlerDelete= async (itemId) => {
  const confirmDelete = window.confirm('Are you sure you want to delete?');
    if (confirmDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_HOST}/employees/${itemId?.id}`,);
        alert('Delete Successfully')
       isRefetching()
      } catch (error) {
        
        console.log('Error',error);
      }
      console.log('Deleted!');
    }
  };
  // DELET End API
  console.log('REACT_APP_HOST',process.env.REACT_APP_HOST_URL);
  console.log('REACT_APP_HOST_env',process.env);
  return (

      <div className="container-fluid overflow-hidden --changedNav_table">
        <div className="row sidebar_row overflow-hidden">
        
          <div className="webNavbar sticky-top col-lg-2 px-0  ">
          {/* <div className="col-lg-12 col-sm-3 col-xl-2 px-sm-2 px-0 "> */}

          <SidebarPay/>

          {/* </div> */}
          </div>
          <div className="col-lg-10 d-flex flex-column h-sm-auto">
            <div>
              <div
                style={{
                  textAlign: "end",
                  marginRight: "25px",
                  marginTop: "20px",
                }}
              >
                {" "}
                <input
                  style={{
                    width: "40%",
                    height: "40px",
                    paddingLeft: "10px",
                    border:'2px solid gray',
                    borderRadius:'6px',
                    // border:'none'
                    

                  }}
                  onChange={handleChange}
                  type="search"
                  placeholder="Search"
                />
              </div>
              <div >
                <TableContainer  sx={{ maxHeight: 650 ,marginTop:'20px'}} >
                  <Table  stickyHeader aria-label="sticky table" >
                    <TableHead className="tableHead" style={{background:"#0000"}} >
                      <TableRow className="tableRow">
                        <TableCell className="headerTableCell">S.No</TableCell>
                        <TableCell className="headerTableCell">
                          Employee Id
                        </TableCell>
                        <TableCell className="headerTableCell">
                          Employee Name
                        </TableCell>
                        <TableCell className="headerTableCell">
                          Mobile Number
                        </TableCell>
                        <TableCell className="headerTableCell">D.O.B</TableCell>
                        <TableCell className="headerTableCell">
                          Designation
                        </TableCell>
                        <TableCell className="headerTableCell">
                          Email Id
                        </TableCell>
                        <TableCell className="headerTableCell">
                          Role
                        </TableCell>
                        <TableCell className="headerTableCell">
                          Edit/Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                      {/* <div style={{height:"130px",overflow:"hidden"}}> */}
                    <TableBody className="viewUserTableBody" style={{overflowY: 'scroll', maxHeight: '400px'}}>

                      {isLoading?
                      <Loading/>
                      :
                        searchName?.length === 0 ? (
                          data&&data?.data.map((data,i) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell className="rowTableCell">
                                    {i+1}
                                  </TableCell>
    
                                  <TableCell className="rowTableCell">
                                    {data.employeeId}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.name}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.mobileNumber}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.dob}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.designation}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.emailId}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.role}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    
                                    <img
                                      style={{ width: "20px",cursor:'pointer' }}
                                      onClick={() => {
                                       
                                        navigate("/addUser", { state: data });
                                       
                                      }}
                                      src={edit}
                                      alt="edit-icon"
                                    />
                                    <img
                                      style={{ width: "20px", marginLeft: "10px",cursor:'pointer' }}
                                      src={remove}
                                      alt="delete-icon"
                                      onClick={()=>HandlerDelete(data)}
                                    />
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ): searchResult?.length > 0 ? (
                          searchResult?.map((data,i) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell className="rowTableCell">
                                    {i+1}
                                  </TableCell>
    
                                  <TableCell className="rowTableCell">
                                    {data.employeeId}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.name}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.mobileNumber}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {moment(data.dob).format("DD-MM-YYYY")}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.designation}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.emailId}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    {data.role}
                                  </TableCell>
                                  <TableCell className="rowTableCell">
                                    
                                    <img
                                      style={{ width: "20px",cursor:'pointer' }}
                                      onClick={() => {
                                       
                                        navigate("/addUser", { state: data });
                                       
                                      }}
                                      src={edit}
                                      alt="edit-icon"
                                    />
                                    <img
                                      style={{ width: "20px", marginLeft: "10px",cursor:'pointer' }}
                                      src={remove}
                                      alt="delete-icon"
                                      onClick={()=>HandlerDelete(data)}
                                    />
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })

                        ):(
                          <h4>No record found</h4>
                        )
                       }
                    </TableBody>
                      {/* </div>  filteredNameData === undefined? */}
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

// :

// filteredNameData.length !== 0 ? ( filteredNameData.map((data,i) => {
// console.log("val", data);
// console.log(
//   "logdate",
//   moment(data.dob).format("DD-MM-YYYY")
// );
// return (
//   <>
//     <TableRow>
//       <TableCell className="rowTableCell">
//       {i+1}
//       </TableCell>

//       <TableCell className="rowTableCell">
//         {data.employeeId}
//       </TableCell>
//       <TableCell className="rowTableCell">
//         {data.name}
//       </TableCell>
//       <TableCell className="rowTableCell">
//         {data.mobileNumber}
//       </TableCell>
//       <TableCell className="rowTableCell">
//         {moment(data.dob).format("DD-MM-YYYY")}
//       </TableCell>
//       <TableCell className="rowTableCell">
//         {data.designation}
//       </TableCell>
//       <TableCell className="rowTableCell">
//         {data.emailId}
//       </TableCell>
//       <TableCell className="rowTableCell">
//         {data.role}
//       </TableCell>
//       <TableCell className="rowTableCell">
//         {/* Edit/Delete */}
//         <img
//           style={{ width: "20px" }}
//           onClick={() => {
//             //  props.setViewUserEdit(data);
//             navigate("/addUser", { state: data });
//             //  navigate("/addUser");
//           }}
//           src={edit}
//           alt="edit-icon"
//         />
//         <img
//           style={{ width: "20px", marginLeft: "10px" }}
//           src={remove}
//           alt="delete-icon"
//           onClick={()=>HandlerDelete(data)}
//         />
//       </TableCell>
//     </TableRow>
//   </>
// );
// })) :(
// <h4 style={{width:"113%"}}>No record found</h4>
// )