import React from "react";

export default function InputField(props) {
// console.log("DDDDDD<<<>",props)
  return (
    <div>
      <input
        className={`inputFiledCss ${props?.viewEmployeeCss} ${props?.loginPageCss} ${props.updatepayslippage}`}
        type={props.type}
        placeholder={props.placeholder}
        {...props?.field}
        disabled={props?.disabled}
        maxDate={props?.maxDate}
      />
    </div>
  );
}
