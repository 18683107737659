import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";



export default function SidebarPay() {
  const navigate = useNavigate();
  let locations=useLocation() 
  let location; 
  useEffect(()=>{
    location=locations
  },[locations])
  const user = sessionStorage.getItem("user");
  const username = sessionStorage.getItem("username");
  // console.log('HELLO<<<<>>>>>',user,user===null,user==='undefined');
  // console.log(undefined);
  console.log('====================================',location);
  return (

  <>
     <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start pt-2 text-white side_menu_list_items">
           
            <h3 className="px-3">{username!=='undefined'?username&&username?.charAt(0).toUpperCase() + username.slice(1) : "User"}</h3>
            <p className="px-3">{user!=='undefined'?user&&user?.charAt(0).toUpperCase() + user.slice(1) : "User"}</p>
              {
                user!=='employee'?
                user =='superAdmin'?
              <ul
                className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start w-100"
                id="menu"
              >
                <li className={`nav-item side_barhead ${location&&location?.pathname=='/'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="nav-link px-sm-0 "
                   
                  >
                    <span  className="side_barlist ">View User</span>
                  </a>
                </li>
                <li className={`nav-item side_barhead ${location&&location?.pathname=='/AddUser'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/AddUser");
                    }}
                    data-bs-toggle="collapse"
                    className="nav-link px-sm-0 "
                   
                  >
                    <span className="side_barlist ">Add User</span>
                   
                  </a>
                </li>
                <li className={`nav-item side_barhead ${location&&location?.pathname=='/ViewEmployee'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/ViewEmployee");
                    }}
                    className="nav-link px-sm-0 "
                   
                  >
                    <span className="side_barlist ">View Employee</span>
                    {/* <span className="ms-1 d-none d-sm-inline"></span> */}
                  </a>
                </li>

                <li className={`nav-item side_barhead ${location&&location?.pathname=='/updatePaySlip'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/updatePaySlip");
                    }}
                    className="nav-link px-sm-0 "
                   
                  >
                    <span className="side_barlist">Update PaySlip</span>
                    {/* <span className="ms-1 d-none d-sm-inline">Products</span> */}
                  </a>
                </li>
                <li className={`nav-item side_barhead ${location&&location?.pathname=='/editProfile'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/editProfile");
                    }}
                    className="nav-link px-sm-0 "
                   
                  >
                    <span className="side_barlist">Edit Profile</span>
                    {/* <span className="ms-1 d-none d-sm-inline">Products</span> */}
                  </a>
                </li>
              </ul>
                : 
                <ul
                className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start w-100"
                id="menu"
              >
               
                <li className={`nav-item side_barhead ${location&&location?.pathname=='/ViewEmployee'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="nav-link px-sm-0 "
                   
                  >
                    <span className="side_barlist ">View Employee</span>
                    {/* <span className="ms-1 d-none d-sm-inline"></span> */}
                  </a>
                </li>

                <li className={`nav-item side_barhead ${location&&location?.pathname=='/updatePaySlip'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/updatePaySlip");
                    }}
                    className="nav-link px-sm-0 "
                   
                  >
                    <span className="side_barlist">Update PaySlip</span>
                    {/* <span className="ms-1 d-none d-sm-inline">Products</span> */}
                  </a>
                </li>
                <li className={`nav-item side_barhead ${location&&location?.pathname=='/editProfile'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/editProfile");
                    }}
                    className="nav-link px-sm-0 "
                   
                  >
                    <span className="side_barlist">Edit Profile</span>
                    {/* <span className="ms-1 d-none d-sm-inline">Products</span> */}
                  </a>
                </li>
              </ul>
                
                
              :
              <ul
                className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start w-100"
                id="menu"
              >
              
                <li className={`nav-item side_barhead ${location&&location?.pathname=='/'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="nav-link px-sm-0 "
                   
                  >
                    <span className="side_barlist ">View Employee</span>
                  
                  </a>
                </li>

              
                {/* <li className={`nav-item side_barhead ${location&&location.pathname=='/editProfile'?'active':''}`}>
                  <a
                    onClick={() => {
                      navigate("/editProfile");
                    }}
                    className="nav-link px-sm-0 "
                   
                  >
                    <span  className="side_barlist ">Edit Profile</span>
                  </a>
                </li> */}
              </ul>

              }
              <a
                onClick={() => {
                  sessionStorage.removeItem('login');
                  sessionStorage.removeItem('user');
                  sessionStorage.removeItem('logo');
                  sessionStorage.removeItem('companyname');
                  navigate("/");
                  window.location.reload();
                }}
                className="d-flex align-items-center logout_btn text-white text-decoration-none"
              >
                <span className="fs-6 logout_btn" style={{ padding: "10px" }}>
                  Log out
                </span>
              </a>
            </div>
  </>
  );
}
