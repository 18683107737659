import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useMutation } from "react-query";
import axios from "axios";
import SidebarPay from "../component/Sidebar";
import showIcon from "../asset/pasShow.png";
import hiddenIcon from "../asset/pashidden.png";
// import {REACT_APP_BASE_URL} from '../e'
export default function AddUserPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [errorPassword, setErrorPassword] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isPass, setIsPass] = useState(false);
  const user = sessionStorage.getItem("user");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const dateMoment = moment().format("L");

  console.log("name_state?.dob", new Date(`15/05/2024`),state&&state?.dob,`${state&&state?.dob}`,moment(state&&state?.dob,'DD/MM/YYYY').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'));

  useEffect(() => {
    setValue("id", state?.employeeId);
    setValue("name", state?.name);
    setValue("mobileNumber", state?.mobileNumber);
    setValue("emailId", state?.emailId);
    // setValue("dob", state&&state?.dob);
    setValue("designation", state?.designation);
    setValue("companyName", state?.companyName);
    setValue("role", state?.role);
  }, [state]);
  // API Start
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/employees/addemployee`,
        postData
      ),
    {
      onSuccess: (data) => {
        console.log("SuccessFull", data?.data);
        alert("User Added SuccessFully");
        reset();
        navigate("/");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  // API End
  // Update API Start
  const updata = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/employees/${state?.id}`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: (data) => {
        // Handle successful mutation
        alert("Product Update Successfully");
        reset();
        navigate("/");
      },
      onError: (error) => {
        // Handle error
        console.error("Mutation failed:", error);
      },
    }
  );

  const { password, confirmPassword } = watch();
  console.log("check", password, confirmPassword);

  const onSubmit = (data) => {
    // navigate("/viewUser");
    // requiredTrue(data)
    let value = {
      employeeId: data && data?.id,
      name: data && data?.name,
      mobileNumber: data && data?.mobileNumber,
      emailId: data && data?.emailId,
      dob: moment(data && data?.dob).format("DD/MM/YYYY"),
      designation: data && data?.designation,
      password: data && data?.password,
      confirmpassword: data && data?.confirmPassword,
      companyName: data && data?.companyName,
      role: data && data?.role,
    };
    // reset();
    if (password !== confirmPassword) {
      setErrorPassword(true);
    } else if (state && state?.id) {
      updata.mutate(value);
    } else {
      mutate(value);
    }
    // mutate(value);
    // updata.mutate(value)
  };
  console.log("data_Add_user", user);
  const [isLoadings, setIsLoadings] = useState(false);
  const handleLogin = () => {
    setIsLoadings(true);
    setTimeout(() => {
      setIsLoadings(false);
    }, 2000);
  };

  return (
    <div>
      <div className="container-fluid overflow-hidden --changedNav_table">
        <div className="row sidebar_row overflow-auto">
          <div className="webNavbar sticky-top col-lg-12 col-sm-3 col-xl-2 px-0  ">
            {/* <div className="col-lg-12 col-sm-3 col-xl-2 px-sm-2 px-0 "> */}

            <SidebarPay />

            {/* </div> */}
          </div>
          <div className="col d-flex flex-column h-sm-auto justify-content-center form_main_head">
            {/* <AddUser /> */}
            <div>
              <div
                style={{
                  maxWidth: "1000px",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    border: "1px solid #adadad",
                    borderRadius: "9px",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
                  }}
                >
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="add_user_form_header"
                  >
                    <div className="add_user_form">
                      <label style={{ fontSize: "20px", fontWeight: "600" }}>
                        Employee Id *
                      </label>
                      <div>
                      <Controller
                        control={control}
                        name="id"
                        render={(field) => (
                          <InputField
                            // customerInputCss="--customerInputCss"
                            // style={{ border : requiredError ? 'red' : 'initial' }}
                            {...field}
                            placeholder="Employee ID"
                            type="text"
                          />
                        )}
                        rules={{ required: "ID is required" }}
                      />
                       {errors.id && <p style={{color:"red",}}>{errors.id.message}</p>}
                      </div>
                    </div>

                    <div className="add_user_form">
                      <label style={{ fontSize: "20px", fontWeight: "600" }}>
                        Employee Name
                      </label>
                 
                      <Controller
                        control={control}
                        name="name"
                        render={(field) => (
                          <InputField
                            {...field}
                            placeholder="Employee Name"
                            type="text"
                          />
                        )}
                        rules={{ required: "DOB is required" }}
                      />
                      
                    </div>
                    <div className="add_user_form">
                      <label style={{ fontSize: "20px", fontWeight: "600" }}>
                        Mobile Number
                      </label>

                      <Controller
                        control={control}
                        name="mobileNumber"
                        render={(field) => (
                          <InputField
                            {...field}
                            placeholder="Mobile Number"
                            type="number"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="add_user_form">
                      <label style={{ fontSize: "20px", fontWeight: "600" }}>
                        Email Id
                      </label>

                      <Controller
                        control={control}
                        name="emailId"
                        render={(field) => (
                          <InputField
                            {...field}
                            placeholder="Email Id"
                            type="text"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="add_user_form">
                      <label style={{ fontSize: "20px", fontWeight: "600" }}>
                        DOB *
                      </label>

                      {/* <Controller
                        control={control}
                        name="dob"
                        render={({ field }) => (
                          <DatePicker
                            className="addUserDatePicker"
                            {...field}
                            // selected={dob}
                            // onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            placeholderText="DOB"
                            selected={field.value}
                            // value={value}
                            onChange={(date) => field.onChange(date)}
                          />
                        )}
                        rules={{ required: true }}
                      /> */}
                      <div style={{display:"flex",flexDirection:'column'}}>
                      <Controller
                        name="dob"
                        control={control}
                        rules={{ required: "DOB is required" }}
                        render={({ field }) => (
                          <DatePicker
                          className="addUserDatePicker"
                            // placeholderText="Select date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            placeholderText="DOB"
                          />
                        )}
                      />
                      {errors.dob && <p style={{color:"red"}}>{errors.dob.message}</p>}
                      </div>

                      {/* {dob && <p>Your age: {calculateAge(dob)}</p>} */}
                    </div>
                    <div className="add_user_form">
                      <label style={{ fontSize: "20px", fontWeight: "600" }}>
                        Designation
                      </label>

                      <Controller
                        control={control}
                        name="designation"
                        render={(field) => (
                          <InputField
                            {...field}
                            placeholder="Designation"
                            type="text"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    <div className="add_user_form">
                      <label style={{ fontSize: "20px", fontWeight: "600" }}>
                        New Password{" "}
                      </label>
                      {/* <input style={{border:'solid 2px gray',borderRadius:'6px',height:'60px',padding:'10px',backgroundColor:'lightgray'}} type="file"  {...register("uploadFile", {
                       required: true,
                     })} /> */}
                      <div>
                        <Controller
                          control={control}
                          name="password"
                          render={(field) => (
                            <InputField
                              // viewEmployeeCss="viewEmployeeCss"
                              {...field}
                              placeholder="password"
                              type={`${isPass ? "text" : "password"}`}
                            />
                          )}
                          rules={{ required: true }}
                        />
                        <div
                          className="addUserPasswordIcon"
                          onClick={() => setIsPass(!isPass)}
                        >
                          {isPass ? (
                            <img
                              src={showIcon}
                              alt="Show"
                              style={{ width: "25px" }}
                            />
                          ) : (
                            <img
                              src={hiddenIcon}
                              alt="Show"
                              style={{ width: "25px" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="add_user_form">
                        <label style={{ fontSize: "20px", fontWeight: "600" }}>
                          Confirm password
                        </label>
                        <div>
                          <Controller
                            control={control}
                            name="confirmPassword"
                            render={(field) => (
                              <InputField
                                // viewEmployeeCss="viewEmployeeCss"
                                {...field}
                                placeholder="Confirm Password"
                                type={`${isShow ? "text" : "password"}`}
                              />
                            )}
                            rules={{ required: true }}
                          />
                          <div
                            className="addUserPasswordIcon"
                            onClick={() => setIsShow(!isShow)}
                          >
                            {isShow ? (
                              <img
                                src={showIcon}
                                alt="Show"
                                style={{ width: "25px" }}
                              />
                            ) : (
                              <img
                                src={hiddenIcon}
                                alt="Show"
                                style={{ width: "25px" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: "end" }}>
                        {errorPassword && (
                          <i style={{ color: "red", paddingLeft: "10px" }}>
                            Passwords do not match
                          </i>
                        )}
                      </div>
                    </div>
                    <div className="add_user_form">
                      <label style={{ fontSize: "20px", fontWeight: "600" }}>
                        Company Name *
                      </label>
                      {/* <input style={{border:'solid 2px gray',borderRadius:'6px',height:'60px',padding:'10px',backgroundColor:'lightgray'}} type="file"  {...register("uploadFile", {
                       required: true,
                     })} /> */}
                     <div style={{display:"flex",flexDirection:'column'}}>
                      <Controller
                        control={control}
                        name="companyName"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            className="inputFiledCss"
                            value={value && value}
                            onChange={onChange}
                            style={{
                              "text-transform": "capitalize",
                              width: "190px",
                              outline: "none",
                            }}
                          >
                            <option>None</option>
                            <option value="IPF FMS">IPF FMS</option>
                            <option value="IPF">IPF</option>

                            <option value="SIFM">SIFM</option>
                          </select>
                        )}
                        rules={{ required: "Company Name is required" }}
                      />
                       {errors.companyName && <p style={{color:"red"}}>{errors.companyName.message}</p>}
                     
                     </div>
                    </div>
                    <div className="add_user_form">
                      <label style={{ fontSize: "20px", fontWeight: "600" }}>
                        Role *
                      </label>
                      <div style={{display:"flex",flexDirection:'column'}}>
                      <Controller
                        control={control}
                        name="role"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            className="inputFiledCss"
                            value={value && value}
                            onChange={onChange}
                            style={{
                              "text-transform": "capitalize",
                              width: "190px",
                              outline: "none",
                            }}
                          >
                            <option>None</option>
                            <option value="admin">Admin</option>
                            {user == "superAdmin" ? (
                              <option value="superAdmin">Super Admin</option>
                            ) : (
                              <></>
                            )}
                          </select>
                        )}
                        rules={{ required: "Role is required" }}
                      />
                         {errors.role && <p style={{color:"red"}}>{errors.role.message}</p>}
                   
                 </div>
                     
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginRight: "20px",
                      }}
                    >
                      <button
                        style={{
                          width: "200px",
                          height: "40px",
                          marginTop: "30px",
                          borderRadius: "6px",
                          border: "none",
                          backgroundColor: "yellowGreen",
                        }}
                        type="submit"
                        onClick={handleLogin}
                      >
                        {isLoadings ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
