import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import menu from "../asset/menu.png";
import close from "../asset/close.png";
const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeNavbar = () => setIsOpen(false);
  const closeNavbarlogo = () => {
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("logo");
    sessionStorage.removeItem("companyname");
    navigate("/");
    window.location.reload();
    setIsOpen(false);
  };
  const navRef = useRef(); // Create a ref for the nav element

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  const user = sessionStorage.getItem("user");
  const companyLogo = sessionStorage.getItem("logo");
  return (
    <div className="mobile_navbar">
      <nav className=" --changedNav" ref={navRef}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            background: "#fff",
          }}
        >
          <NavLink
            className="navbar-brand fw-bold fs-4 px-2 nav_logo_website"
            to="/"
            onClick={closeNavbar}
          >
            <img
              style={{ width: "60px", height: "50px", marginTop: "8px" }}
              src={companyLogo}
              className="logo_virtualworld"
            />
          </NavLink>
          <div
            className="menu-toggle"
            onClick={toggleDropdown}
            style={{ textAlign: "end", paddingRight: "10px" }}
          >
            {!isOpen ? (
              <img src={menu} style={{ width: "9%" }} />
            ) : (
              <img src={close} style={{ width: "10%" }} />
            )}
          </div>
        </div>
        {isOpen && (
          <ul className="nav-menu">
            {user !== "employee" ? (
              <>
              {user =='superAdmin'?
              <>
                <li>
                  <NavLink
                    to="/"
                    onClick={closeNavbarlogo}
                    className="nav_item_list"
                  >
                    Logout
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    {" "}
                    View User
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/AddUser"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    Add User
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/ViewEmployee"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    View Employee
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/updatePaySlip"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    Update Pay Slip
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/editProfile"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    Edit Profile
                  </NavLink>
                </li>
               </>
              :
              <>
                <li>
                  <NavLink
                    to="/"
                    onClick={closeNavbarlogo}
                    className="nav_item_list"
                  >
                    Logout
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink
                    to="/"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    {" "}
                    View User
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink
                    to="/AddUser"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    Add User
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    to="/"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    View Employee
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/updatePaySlip"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    Update Pay Slip
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/editProfile"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    Edit Profile
                  </NavLink>
                </li>
                </>
              
              }
              </>
            ) : (
              <>
                <li>
                  <NavLink
                    to="/"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    Logout
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/ViewEmployee"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    View Employee
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/editProfile"
                    onClick={closeNavbar}
                    className="nav_item_list"
                  >
                    Edit Profile
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
