import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import SidebarPay from "../component/Sidebar";
import { useMutation } from "react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function UpdatePaySlipPage() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
  });
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/employees/upload`,
        postData
      ),
      // { headers: { 'Content-Type': 'multipart/form-data' } },
    {
      onSuccess: (data) => {
        console.log("SuccessFull", data?.data);
        alert("Added SuccessFully");
        //   reset()
        navigate("/");
      },
      onError: (error) => {
        alert("File is Not Support");
        reset();
      },
    }
  );
  // API End
  const companyName = sessionStorage.getItem("companyname");
  const onSubmit = (data) => {
    // navigate("/sidebar");
    console.log("<<<<>>>>",data)
    let value = {
      file: data?.uploadFile[0],
    };
    let formData = new FormData();

    // Append the file to the formData object. 'file' is the key.
    formData.append("file", data.uploadFile[0]);
    console.log("data_paySlip_upload", formData, data);
    if(companyName === "SIFM"){
      mutate(formData)
    }else if(companyName === "IPF"){
      mutate(formData)
    }else if(companyName === "IPF FMS"){
      mutate(formData)

    }

  };
  // const [isLoadings, setIsLoadings] = useState(false);
  // const handleLogin = () => {
  //   setIsLoadings(true);
  //   setTimeout(() => {
  //     setIsLoadings(false);
  //   }, 1000);
  // };
  return (
    <div>
      <div className="container-fluid overflow-hidden --changedNav_table">
        <div className="row sidebar_row overflow-auto">
          <div className="webNavbar   sticky-top col-lg-12 col-sm-3 col-xl-2  px-0  ">
            {/* <div className="col-lg-12 col-sm-3 col-xl-2 px-sm-2 px-0 "> */}

            <SidebarPay />

            {/* </div> */}
          </div>
          {/* <div className="col-12 col-sm-3 col-xl-2 px-sm-2 px-0 bg-dark d-flex sticky-top">
            <SidebarPay/>
            </div> */}
          <div className="col d-flex flex-column h-sm-auto">
            <div style={{ height: "100%" }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div style={{ margin: "20px 10px" }}>
                    <label
                      style={{
                        fontSize: "18px",
                        fontWeight: "800",
                        marginRight: "20px",
                        padding: "20px 20px 10px 10px",
                      }}
                    >
                      Upload File
                    </label>
                    <br />
                    <input
                      style={{
                        border: errors.name
                          ? "2px solid red"
                          : "2px solid gray",
                        // border: "solid 2px ",
                      }}
                      className="viewEmployeeCss"
                      type="file"
                      accept=".csv"
                      {...register("uploadFile", { required: true })}
                    />
                  </div>

                  <div
                    style={{
                      // width: "400px",
                      display: "flex",
                      justifyContent: "center",
                      paddingLeft: "30px",
                    }}
                  >
                    <button
                      style={{
                        width: "200px",
                        height: "40px",
                        marginTop: "30px",
                        borderRadius: "6px",
                        border: "none",
                        backgroundColor: "yellowGreen",
                      }}
                      type="submit"
                    >
                      {isLoading ? (
                        <i class="fa fa-spinner fa-spin"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
