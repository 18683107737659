import React from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SidebarPay from "../component/Sidebar";
import { useState } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import moment from "moment";

export default function EditProfile() {
  const [errorPassword, setErrorPassword] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
  });
    // API Start
    const { mutate, isLoading, isError, isSuccess } = useMutation(
      (postData) =>
        axios.post(
          `${process.env.REACT_APP_HOST}/employees/reset`,
          postData
        ),
      {
        onSuccess: (data) => {
          console.log("SuccessFull", data?.data);
          alert("User Profile Update SuccessFully");
          reset();
          // navigate("/");
        },
        onError: (error) => {
          alert("Invalid EmployeeId or DOB");
        },
      }
    );
    // API End
  const onSubmit = (data) => {
    // navigate("/sidebar");
    let value={
      value1:data?.employeeId,
      value2: moment(data?.dob).format("DD/MM/YYYY"),
      value3:data?.confirmPassword
    }
    // if (data?.currentPassword !== data?.confirmPassword){
    //   setErrorPassword(true)
      
    // }else{
      mutate(value)
    // }
  }
  const { currentPassword, confirmPassword } = watch();
   

  const [isLoadings, setIsLoadings] = useState(false);
  const handleLogin = () => {
    setIsLoadings(true);
    setTimeout(() => {
      setIsLoadings(false);
    }, 1000);
  };
  return (
    <div>
      <div className="container-fluid overflow-hidden --changedNav_table">
        <div className="row sidebar_row overflow-auto">
          <div className="webNavbar  sticky-top col-lg-12 col-sm-3 col-xl-2 px-0  ">
            {/* <div className="col-lg-12 col-sm-3 col-xl-2 px-sm-2 px-0 "> */}

            <SidebarPay />

            {/* </div> */}
          </div>
          {/* <div className="col-12 col-sm-3 col-xl-2 px-sm-2 px-0 bg-dark d-flex sticky-top">
        <SidebarPay/>
          </div> */}
          <div className="col d-flex flex-column h-sm-auto abs">
            <div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="edit_user_profile"
              >
                <div style={{ margin: "20px 10px" }}>
                  <label
                    style={{
                      fontSize: "18px",
                      fontWeight: "800",
                      marginRight: "20px",
                      padding: "20px 20px 10px 10px",
                    }}
                  >
                    Employee Id
                  </label>
                  {/* <input style={{border:'solid 2px gray',borderRadius:'6px',height:'60px',padding:'10px',backgroundColor:'lightgray'}} type="file"  {...register("uploadFile", {
                       required: true,
                     })} /> */}
                  <Controller
                    control={control}
                    name="employeeId"
                    render={(field) => (
                      <InputField
                        viewEmployeeCss="--updatepayslippage"
                        {...field}
                        placeholder="Employee ID"
                        type="text"
                        required={true}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div style={{ margin: "20px 20px" }}>
                  <label
                    style={{
                      fontSize: "18px",
                      fontWeight: "800",
                      // marginRight: "20px",
                      padding: "20px 20px 10px 10px",
                    }}
                  >
                    DOB
                  </label>
                  <br />
                  <Controller
                    control={control}
                    name="dob"
                    render={({ field }) => (
                      <DatePicker
                        className="--updatepayslippage"
                        {...field}
                        // selected={dob}
                        // onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        placeholderText="DOB"
                        selected={field.value}
                        // value={value}
                        onChange={(date) => field.onChange(date)}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div style={{ margin: "20px 10px" }}>
                  <label
                    style={{
                      fontSize: "18px",
                      fontWeight: "800",
                      // marginRight: "20px",
                      padding: "20px 20px 10px 10px",
                    }}
                  >
                    New Password
                  </label>
                  {/* <input style={{border:'solid 2px gray',borderRadius:'6px',height:'60px',padding:'10px',backgroundColor:'lightgray'}} type="file"  {...register("uploadFile", {
                       required: true,
                     })} /> */}
                  <Controller
                    control={control}
                    name="newPassword"
                    render={(field) => (
                      <InputField
                        viewEmployeeCss="--updatepayslippage"
                        {...field}
                        placeholder="New Password"
                        type="password"
                        required={true}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div style={{ margin: "20px 10px" }}>
                  <label
                    style={{
                      fontSize: "18px",
                      fontWeight: "800",
                      // marginRight: "20px",
                      padding: "20px 20px 10px 10px",
                    }}
                  >
                    Confirm password
                  </label>
                  {/* <input style={{border:'solid 2px gray',borderRadius:'6px',height:'60px',padding:'10px',backgroundColor:'lightgray'}} type="file"  {...register("uploadFile", {
                       required: true,
                     })} /> */}
                  <Controller
                    control={control}
                    name="confirmPassword"
                    render={(field) => (
                      <InputField
                        viewEmployeeCss="--updatepayslippage"
                        {...field}
                        placeholder="Confirm Password"
                        type="password"
                      />
                    )}
                    rules={{ required: true }}
                  />
                  {errorPassword && (
                    <i style={{ color: "red", paddingLeft: "10px" }}>
                      Passwords do not match
                    </i>
                  )}
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                  }}
                >
                  <button
                    style={{
                      width: "200px",
                      height: "40px",
                      marginTop: "30px",
                      borderRadius: "6px",
                      border: "none",
                      backgroundColor: "yellowGreen",
                    }}
                    type="submit"
                    onClick={handleLogin}
                  >
                    {isLoadings ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
