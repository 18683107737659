import axios from "axios";
import { useMutation } from "react-query";
import React from "react";
// import {REACT_APP_HOST} from '@env';
export const fetchData = async ({params}) => {
    console.log('params_data',params);
     try {
       const response = await axios.get(`${process.env.REACT_APP_HOST}employees/payroll`,{params});
       return response.data;
     } catch (error) {
       throw new Error('Failed to fetch data');
     }
   };
   export const getUserTable  = async ({
    customerId,
    organization = null,
  }) => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/employees/allemployees`,
    );
  };

  export const getPaySlipDetail  = async ({
    customerId,
    organization = null,
  }) => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/employees/allPaySlipDetails`,
    );
  };
  export const SignUp = async ({params,setIsLabel}) => {
    console.log('params_data',params);
     try {
       const response = await axios.get(`${process.env.REACT_APP_HOST}/employees/login`, { params });
       console.log("DATLOGIN_PARAMS",response.data.role);
       sessionStorage.setItem("user", response.data.role);
       return response.data;
     } catch (error) {
       throw new Error('Failed to fetch data');
     }
   };
   