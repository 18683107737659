import React, { useEffect, useState } from "react";
// import ipfLogo from "../asset/ipfLogo.jpeg";
import { Controller, useForm } from "react-hook-form";
import ipfLogo from "../asset/ipfLogo.jpeg";
import ipfplLogo from "../asset/ipfplLogo.JPG";
import sifmLogo from "../asset/sifmLogo.JPG";
// import { login } from "../redux/reducer/handleCart";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputField from "./InputField";
import { useQuery } from "react-query";
import { SignUp } from "../globalAPI/globalAPI";
import ButtonLoading from "./ButtonLoading";
import { red } from "@mui/material/colors";

export default function Login(props) {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [loginError,setLoginError] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
  });
  const [isValue, setIsValue] = useState();
  const [isLoadings, setIsLoadings] = useState(false);

  const [type, setType] = useState("password");
  const user = sessionStorage.getItem("companyname");
  const onSubmit = (data) => {
    let datas = {
      employeeid: data?.employeeId,
      password: data?.password,
      companyname: user,
    };
    // sessionStorage.setItem("login", true);
    // sessionStorage.setItem("user", 'superAdmin');
    // sessionStorage.setItem("userId", '222');
    // sessionStorage.setItem("username", 'Azarudhin');'
    // if(data?.employeeId=="emp123"&&data?.password==="Admin@123"){
    //   sessionStorage.setItem("login", true);
    //   sessionStorage.setItem("user", 'superAdmin');
    //   sessionStorage.setItem("userId", 'emp123');
    //   sessionStorage.setItem("username", 'Azar');
    //   navigate("/");
    //   window.location.reload();
    // }else{

      setIsValue(datas);
      setShouldFetch(true);
      console.log("LOGIN_DATA_VALUE", user, datas);
    // }
    // dispatch(login({ data }));
  };

  const passwordType = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

   const logo = sessionStorage.getItem("logo");
   console.log('USwelog',logo);

  const [shouldFetch, setShouldFetch] = useState(false);
  useEffect(()=>{
if(logo==null){
  navigate("/");
}
  },[logo])

  const { isLoading, isError, data } = useQuery(
    ["user"],
    () => SignUp({ params: isValue }),
    {
      enabled: shouldFetch,
      // Automatically reset the trigger on query completion or error
      onSettled: () => setShouldFetch(false),
      onSuccess: (data) => {
        console.log("DATA_LOGINSUCESS", data);
        const parts = data.split('*');
  
        // Creating an object with id and role
        const user = {
          id: parts[0],
          role: parts[1],
          name:parts[2]
        };
        if(data!=='Invalid user'){

          sessionStorage.setItem("login", true);
          sessionStorage.setItem("user", user?.role);
          sessionStorage.setItem("userId", user?.id);
          sessionStorage.setItem("username", user?.name);
          navigate("/");
          window.location.reload();
        }else{
          setLoginError(true)
        }
        // console.log("DATA_LOGINSUCESS_lllfff",user);
        // sessionStorage.setItem("login", true);
        // sessionStorage.setItem("user", user?.role);
        // sessionStorage.setItem("userId", user?.id);
        // navigate("/");
        // window.location.reload();
      },
      onError:(data)=>{
        setShouldFetch(false)
        setLoginError(true)
      },
    }
  );
  
  console.log("data_VALUE<<<>>>", data);
  const handleLogin = () => {
    setIsLoadings(true);
    setTimeout(() => {
      setIsLoadings(false);
    }, 2000);
  };
  return (
    <div style={{ height: "100%" }}>
      <div className="loginHeader">Login Your Account</div>
      <div className="loginCompanyLogoContainer ">
        <div className="loginFieldMainContainer">
          <div style={{ padding: "0px 18px 18px 18px" }}>
            <img
              style={{
                width: "300px",
                height: "200px",
              }}
              src={logo }
              alt=""
            />
          </div>
        </div>
        <div className="loginInputContainer">
          <form className="loginFormField" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div>
                <label className="loginLabel">Enter your Employee Id</label>
              </div>
              <Controller
                control={control}
                name="employeeId"
                render={(field) => (
                  <InputField
                    {...field}
                    loginPageCss="viewEmployeeCss"
                    placeholder="EmployeeId"
                    type="text"
                    required={true}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
            <div >
              <div>
                <label className="loginLabel">Enter your Password</label>
              </div>
              <Controller
                control={control}
                name="password"
                render={(field) => (
                  <InputField
                    {...field}
                    loginPageCss="viewEmployeeCss"
                    placeholder="Password"
                    type={type}
                    required={true}
                  />
                )}
                rules={{ required: true }}
              />
              {/* <p style={{textAlign:"center",color:'red',fontSize:'18px', marginTop:'10px'}}>InCorrect UserName Password</p> */}
              <div
                onClick={passwordType}
                style={{ marginTop: " 8px", marginLeft: "10px" }}
              >
                <input
                  style={{ paddingLeft: "10px" }}
                  type="checkBox"
                  checked={type == "text" ? "true" : ""}
                />{" "}
                Show Password
              </div>
              {loginError?(<i style={{ color:"red",textAlign:'center'}} >Invalid UserID or Password</i>): <></>}
            </div>
            <div>
              {/* <button className="loginBtn" type="submit"  onClick={handleLogin} disabled={isLoading}>
                  {isLoadings ?  <i class="fa fa-spinner fa-spin" ></i> : 'Login'}

              </button> */}
              <ButtonLoading isLoadings={isLoading} name="Submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
